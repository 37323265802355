/* style.css */

* {
    font-family: 'SK-Modernist', sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: lowercase;
}

content-color-standard {
    color: #d6230b;
}
header {
    text-transform: lowercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    color: #ff5252;
    -webkit-text-stroke-width: 0.0px;
    -webkit-text-stroke-color: black;
    height: 50px;
    margin-bottom: 20px;
}

.header-centered {
    justify-content: center;
}

.logo {
    display: flex;
    align-items: center;
    gap: 20px;
}

.sugar-logo {
    margin: 0;
    padding-bottom: 30px;
}

.sugar-icon {
    margin-left: 10px;
}

.login-button {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1;
    margin-top: 5px;
    font-weight: 900;
    padding: 10px 20px;
    background-color: #ff5252;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.loader {
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: auto;
}

/* style.css */

ul.deck,
ul.deck li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@font-face {
    font-family: 'SK-Modernist';
    src: url('/public/Sk-Modernist-Regular.eot');
    src: url('/public/Sk-Modernist-Regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/public/Sk-Modernist-Regular.woff') format('woff'), /* Pretty Modern Browsers */
        url('/public/Sk-Modernist-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/public/Sk-Modernist-Regular.otf') format('otf'); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

h1 {
    font-size: 90px;
    text-align: center;
}

ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
}

ol {
    margin-right: 10px;
}

.logo img {
    width: 68px;
    height: 68px;
}

.logo a {
    text-decoration: none;
    color: inherit;
}

a {
    all: unset;
}

html {
    background-image: linear-gradient(-135deg, white, beige, #cfb287d9);
    background-repeat: no-repeat;
    min-height: 100vh;
}

.rec {
    background-color: #e5e7eb;
    border: 2px solid black;
    border-radius: 15px;
    font-size: 15px;
    width: 50%;
    margin: auto;
    padding: 10px;
    margin-bottom: 20px;
    text-transform: lowercase;
}

.tag_and_vote {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px 0px 0px 0px;
    margin-bottom: -5px;
    font-family: 'Poppins', sans-serif;
}

.vote-buttons {
    margin-bottom: -5px;
}

.photos {
    width: 50%;
    margin: auto;
}

.photo {
    width: 100%;
    height: 300px; /* Set a fixed height for the images */
    object-fit: cover; /* Ensure the images cover the area without distortion */
}

.rec_name {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: #e94057;
    padding: 0px;
    margin-top: 10px;
}

.fact-list {
    display: flex;
    gap: 20px;
    padding-bottom: 20px;
}

.recs-list {
    gap: 20px;
    padding-bottom: 20px;
    list-style-type: decimal;
}

.filter-container {
    display: flex;
    justify-content: center;
    gap: 10px; /* Space between buttons */
}

.filter-button {
    font-family: 'Poppins', sans-serif;
    display: inline-block;
    padding: 5px 6px;
    margin: 5px;
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border-color: black;
    border-width: 1px;
    text-transform: lowercase;
}

.filter-button:hover {
    transform: translateY(-2px);
    font-weight: bold;
    color: black;
    border-width: 2px;
    transition: 0.1s;
}

.filter-button:disabled {
    cursor: not-allowed;
    border-color: black;
    border-width: 2px;
    font-weight: bold;
    color: black;
}

.filter-button-primary {
    background-color: #007bff;
}

.filter-button-secondary {
    background-color: #6c757d;
}

.filter-button-danger {
    background-color: #dc3545;
}

.filter-button-secondary:hover {
    background-color: #5a6268;
}

.filter-button-danger:hover {
    background-color: #c82333;
}

.filter-button-selected {
    border-color: black;
    border-width: 2px;
    font-weight: bold;
    color: black;
    cursor: not-allowed;
}

.slider-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto; /* Center the container horizontally */
    width: 300px; /* Set the width of the container */
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px; /* Add some space between labels and slider */
}

.slider-labels span {
    font-size: 14px; /* Adjust font size as needed */
}

@media (max-width: 1200px) {
    .rec {
        flex-direction: column;
        gap: 10px;
    }
}

.vote-buttons {
    margin-left: auto;
    display: flex;
    gap: 8px;
    flex-shrink: 0;
    padding-bottom: 10px;
    color: black;
}

.vote-buttons button {
    border: 1px solid black;
    border-radius: 10px;
    font-size: 20px;
    padding: 3px 6px;
    cursor: pointer;
}

.vote-buttons button:visited {
    background-image: linear-gradient(135deg, white, beige, #cfb287d9);
    transform: scale(105%);
    transition: 0.3s;
}

.vote-buttons button:disabled {
    background-color: #e5e7eb;
    color: #a0aec0;
    border: 1px solid black;
    cursor: not-allowed;
}

.main {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    width: 350px;
    height: auto;
    margin: auto; /* Centers the grid horizontally */
}

.hidden {
    display: none !important;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

#root {
    max-width: 1200px;
    margin: 0 auto;
}

section {
    overflow: scroll;
    padding-bottom: 20px;
}

category-page {
    gap: 20px;
    width: 50%;
}

.tag {
    background-color: #cfb287d9;
    color: white;
    padding: 5px 5px 6px 5px;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
}

.fact-form {
    background-color: #cfb287d9;
    border-radius: 1px;
    border-color: black;
    text-align: center;
    padding: 10px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.summary {
    font-size: 16px;
    font-weight: 600;
    padding: 0px;
    text-align: center;
}

.summary {
    margin: 10px;
    text-align: center;
}

.category {
    font-size: 27px;
    text-transform: uppercase;
    line-height: 1;
    margin-top: 5px;
    font-weight: 900;
    padding: 10px 10px;
    background-image: linear-gradient(135deg, white, beige, #cfb287d9);
    border: 4px solid black;
    border-radius: 10px;
    width: 100%;
    background-image: none;
    margin-bottom: 5px;
    cursor: pointer;
}

.area {
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    line-height: 1;
    border: 4px solid white;
    border-radius: 15px;
    margin: 10px 5px;
    background-image: none;
    cursor: pointer;
    color: white;
    padding: 5px 10px;
}

.area-list {
    display: flex;
    width: auto;
    height: 60px;
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 10px;
    margin-bottom: 0px;
}



.onboarding {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.onboarding input {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.share-button {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 900;
    background-image: linear-gradient(135deg, white, beige, #cfb287d9);
    border: 4px solid black;
    border-radius: 10px;
    width: auto;
    background-image: none;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    background-color: #cfb287d9;
    color: black;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
}

.share-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Adjust as needed, e.g., column for vertical alignment */
    gap: -10px; /* Adjust spacing between buttons as needed */
}

.share-button {
    background-color: #ff5252;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
    margin: 10px;
}

.share-button:not(:last-child) {
    margin-bottom: -5px; /* Negative margin to reduce space between buttons */
}

.login-button {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1;
    margin-top: 5px;
    font-weight: 900;
    padding: 10px 20px;
    background-image: linear-gradient(135deg, white, beige, #cfb287d9);
    border: 4px solid black;
    border-radius: 10px;
    width: 10%;
    background-image: none;
    margin-bottom: 5px;
    cursor: pointer;
    text-align: center;
    background-color: #cfb287d9;
}

.category:hover {
    background-image: linear-gradient(135deg, white, beige, #cfb287d9);
    transform: scale(105%);
    transition: 0.5s;
}

.category.active {
    background-image: linear-gradient(135deg, white, beige, #cfb287d9);
    transform: scale(105%);
    transition: 0.5s;
}

.area:hover {
    transform: scale(105%);
    transition: 0.5s;
    border-color: black;
}

.area.active {
    background-color: #cfb287d9;
    transform: scale(105%);
    transition: 0.5s;
    border-color: black;
}

.all-button {
    margin-bottom: 10px;
}

.highly {
    font-weight: 600;
    color: green;
}

  
  
  .form-group {
    display: flex;
    align-items: center;
    margin: 0px 0;
  }
  

  .form-group label {
    flex: 1;
    margin-right: 10px;
    text-align: right;
  }

  .logo img {
    margin-left: -30px;
}

/* style.css */
.view-best-button {
    background-color: #ff5252;
    color: white;
    padding: 10px 20px;
    border: 2px solid white;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;

  }

  
  .fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ff5252;
    color: white;
    border: none;
    border-radius: 20px;
    width: auto;
    padding: 15px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    text-transform: lowercase;
    z-index: 998;
  }

  .fab p {
    margin-top: 15px;
  }
  
  .fab img {
    width: 20px;
    height: 20px;
    margin-left: 7px;
  }

  .decide-with-friends-button {
    background-color: #ff5252;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: -20px;
}
  

.new-feature-container {
    position: relative;
    display: inline-block;
  }
  
  .new-feature-label {
    position: absolute;
    top: 0px;
    right: -14px;
    background-color: #ff4081;
    color: white;
    padding: 2px 6px;
    border-radius: 10px;
    font-size: 0.7em;
    font-weight: bold;
    transform-origin: center;
    transition: transform 0.3s ease;
  }
  
  .new-feature {
    border: 2px solid #fefefe;
    box-shadow: 0 0 7px rgba(255, 64, 129, 0.5);
    transition: all 0.3s ease;
    gap: 10px;
  }
  
  .decide-with-friends-button:hover .new-feature {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(255, 64, 129, 0.7);
  }

  .decide-with-friends-button {
    position: relative;
    overflow: visible;
  }

@media (max-width: 900px) {
    .fact-form {
        flex-direction: column;
        align-items: stretch;
    }
}

@media (max-width: 600px) {
    .main {
        grid-template-columns: 1fr;
        width: 350px;
        height: auto;
        margin: auto;
    }

    .category {
        width: 60%;
        color: black;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .headline {
        font-size: 18px;
        font-weight: 600;
        padding: 0px;
        font-family: 'SK-Modernist', sans-serif;
    }

    ul .category-list {
        align-self: center;    /* Center vertically */
        justify-self: center;  /* Center horizontally */
        list-style-type: none; /* Optional: Remove default list style */
        padding: 0;            /* Optional: Remove default padding */
        margin: 0;             /* Optional: Remove default margin */
        display: flex;         /* Optional: To ensure centering within flex items */
        flex-direction: column; /* Optional: Align list items in a column */
        align-items: center;
        width: 100%;
    }

    ul li .category {
        flex: 1;
        width: 100%; /* Ensures that each <li> takes up the full width of the <ul> */
        box-sizing: border-box; /* Ensures padding and border are included in the width */
        display: flex;
        justify-content: center; /* Center text inside <li> */
        align-items: center; /* Center text vertically inside <li> */
        padding: 10px; /* Optional: Adds padding inside <li> */
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
    }

    #root {
        padding: 24px;
    }

    .fact-form {
        padding: 16px;
    }

    .fact {
        padding: 12px 16px;
    }

    .share-button {
        background-color: #ff5252;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 20px;
        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
        margin: 10px;
    }

    .share-button:not(:last-child) {
        margin-top: -5px; /* Negative margin to reduce space between buttons */
    }

    h1 {
        font-size: 75px;
        text-align: center;
        margin-left: 0px;
        margin-top: 65px;
    }

    .logo img {
        margin-left: -30px;
    }

    h3 {
        font-size: 20px;
    }

    .rec {
        width: 90%;
    }

    nav {
        margin-top: -10px;
    }

    nav .button {
        margin-top: -10px;
    }
}

/* Center the logo and icon for mobile when userEmail exists */
@media (max-width: 600px) {
    .logo {
        justify-content: center;
    }

    .header-centered .login-button {
        display: none;
    }

    .header-centered {
        justify-content: center;
        
    }
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding-bottom: 60px; /* Adjust this value based on the height of your bottom nav bar */
}

.fab {
  bottom: 70px; /* Adjust this value to position the FAB above the bottom nav bar */
}
