.two-column-area-filter {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.area-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.area-button {
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 15px;
  font-size: 17px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.2px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .area-button {
    font-weight: 400;
  }
}

.area-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.area-button.active {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 400px) {
  .area-button {
    font-size: 12px;
    padding: 6px 10px;
  }
}