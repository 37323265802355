.price-filter {
  padding: 0 10px;
  width: 70%; /* Reduced from 80% to 60% to make it less wide */
  max-width: 300px; /* Added max-width for better control on larger screens */
  margin-bottom: 25px;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
}

.price-filter h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #484848;
  text-align: center;
}

.rc-slider-mark {
  font-size: 14px;
  font-weight: 600;
  color: #767676;
  margin-top: 2px;
  transform: translateX(-12px); /* Ensure marks align with the adjusted track */
}

.rc-slider-mark-text-active {
  color: #484848;
}

.rc-slider-handle {
    border-color: #ff5a5f;
    transform: translateX(4px); /* Move handles 4px to the right */
  }

.rc-slider-handle:hover {
  border-color: #ff5a5f;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #ff5a5f;
  box-shadow: 0 0 0 5px rgba(255, 90, 95, 0.2);
}

/* Adjust the track to compensate for handle movement */
.rc-slider-track {
  transform: translateX(-8px);
}
