/* SortFilterButtons.css */

.sort-filter-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .sort-button {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #f2f2f2;
    font-size: 16px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    text-transform: lowercase;
    text-align: center;
    flex: 1 1;
    padding: 10px;
    margin: 0 5px;
    border: none;
    background-color: #f2f2f2;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-weight: 200;
  }
  
  .sort-button-selected {
    cursor: not-allowed;
    border-color: black;
    border-width: 2px;
    font-weight: bold;
    color: black;
    
  }

  .sort-button:disabled {
    cursor: not-allowed;
    border-color: black;
    border-width: 2px;
    font-weight: 600;
    color: black;
  }
  