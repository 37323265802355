.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: -60px;
    margin-top: -10px;
  }
  
  .header-btn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .header-title {
    text-align: center;
  }
  
  .header-title h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }
  
  .toggle-wrapper {
    background-color: #f7f7f7;
    border-radius: 30px;
    padding: 3px;
    position: relative;
    width: 220px; /* Reduced from 240px */
    height: 36px;
    display: flex;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-top: -7px;
  }

  .toggle-wrapper input {
    opacity: 0;
    position: absolute;
  }

  .toggle-wrapper label {
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    padding: 8px 0;
    text-align: center;
    width: 50%;
    z-index: 2;
    transition: color 0.2s ease;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  }

  .toggle-slider {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 35px;
    left: 3px;
    position: absolute;
    top: 3px;
    transition: transform 0.2s ease;
    width: calc(50% - 3px);
  }

  #for-you:checked ~ .toggle-slider {
    transform: translateX(0);
  }

  #near-me:checked ~ .toggle-slider {
    transform: translateX(100%);
  }

  #for-you:checked ~ label[for="for-you"],
  #near-me:checked ~ label[for="near-me"] {
    color: #ff385c;
  }

  #for-you:not(:checked) ~ label[for="for-you"],
  #near-me:not(:checked) ~ label[for="near-me"] {
    color: #717171;
  }

  @media (max-width: 600px) {
    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;
      margin-bottom: -40px;
      margin-top: -20px;
    }

  }