.bottom-nav-bar {
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ff5252;
  border-radius: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
}
.nav-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 0.75rem;
  font-weight: 500;
  transition: transform 0.2s ease;
}

.nav-item:active {
  transform: scale(0.9);
}

.nav-item svg, img {
  font-size: 1.5rem;
  margin-bottom: 4px;
}

.nav-item.active {
  color: #007AFF;
  position: relative;
}

.nav-item.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: #007AFF;
  border-radius: 50%;
}