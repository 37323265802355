.onboarding-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.onboarding-modal-content {
  background: white;
  padding: 20px;
  border-radius: 16px;
  width: 85%;
  max-width: 320px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  font-family: 'Airbnb Cereal', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.welcome-header {
  font-size: 24px;
  font-weight: 700;
  color: #FF5A5F;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.welcome-subheader {
  font-size: 16px;
  color: #484848;
  text-align: center;
  margin-bottom: 10px;
}

.onboarding-steps {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.onboarding-step {
  background-color: #F7F7F7;
  border-radius: 8px;
  padding: 15px;
}

.step-content {
  text-align: center;
}

.step-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.step-icon {
  color: #FF5A5F;
  font-size: 20px;
  margin-right: 8px;
}

.step-content h3 {
  font-size: 16px;
  font-weight: 600;
  color: #484848;
  margin: 0;
}

.step-content p {
  font-size: 14px;
  color: #767676;
  margin: 0;
}

.lets-swipe-button {
  width: 100%;
  padding: 15px;
  background-color: #FF5A5F;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-logo {
  width: 20px;
  height: 20px;
}

@media (max-width: 480px) {
  .onboarding-modal-content {
    width: 90%;
    padding: 15px;
  }
}