/* Modal styles */
.auth-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .auth-modal-content {
    background: white; /* Change from white to transparent */
    padding: 10; /* Remove padding */
    border-radius: 10px;
    position: relative;
    width: 350px; /* Adjust width to fit content */
    max-width: none; /* Remove max-width constraint */
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .auth-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .auth h2 {
    font-size: 28px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  
  .auth .form-group label {
    flex: 1;
    margin-right: 10px;
    text-align: right;
    
  }
  
  .auth .form-group input {
    flex: 2;
    width:  100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .auth .form-container button:hover {
    background-color: #d04343;
  }


.auth input,
.auth select,
.auth button {
    width: 70%;
    padding: 10px;
    margin: 10px 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 14px;
}



.auth input::placeholder,
.auth select {
    color: #555;
    text-align: center;
}

.auth button {
    background-color: #ff5252;
    color: white;
    border: none;
    font-weight: 500;
    cursor: pointer;
}

.auth button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.auth button {
  background-color: #ff5252;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  width: 200px;
}

.auth button:last-child {
  margin-bottom: 10px;
}