.shareable-rec-container {
    width: 50%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
  
  .shareable-rec-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  .shareable-rec-header img {
    width: 30px;
  }

  .back-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  .back-btn img {
    width: 100%;
    height: 100%;
  }

  .shareable-rec-title {
    margin: 0;
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .shareable-rec-loading {
    text-align: center;
    font-size: 18px;
    color: #666;
  }
  
  /* You can add more specific styles here for the RecDetails component if needed */
  
  @media (max-width: 768px) {
    .shareable-rec-container {
      width: 90%;
    }
  }

  .rec-modal-header {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .back-btn-container {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-right: 15px;
  }

  .back-btn-img {
    width: 100%;
    height: 100%;
  }

  .header-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .rec-modal-emoji {
    font-size: 24px;
    margin-right: 10px;
  }

  .rec-modal-name {
    margin: 0;
    font-size: 30px;

  }