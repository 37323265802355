.vibe-tags-container {
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 0px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.vibe-tags-container::-webkit-scrollbar {
  display: none; /* WebKit */
}

.vibe-tags {
  padding-right: 5px;
}

.rec-highlights {
  margin-bottom: 16px;
}

.highlight-tags {
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 8px;
}

.tag-bubble {
  background-color: #ff385c;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.tag-bubble.clicked {
  background-color: #ff385c; /* Airbnb teal color */
  border: 1px solid #fff; /* White border for contrast */
  box-shadow: 0 0 0 1px #e31c5f; /* Outer glow effect */
  transform: scale(1.00); /* Slightly larger */
  font-weight: 600; /* Bolder text */
  cursor: default;
}

.tag-bubble:disabled {
  opacity: 1;
  cursor: not-allowed;
}

.count-wrapper {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  margin-right: -4px;
}

.count {
  color: #fff;
  font-size: 10px;
  font-weight: 600;
}

.add-new-tag {
  background-color: #e0e0e0;
  color: #ff385c;
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-btn-icon {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px; /* This will move the icon down 2 pixels */
}

.new-tag-input-container {
  display: flex;
  align-items: center;
}

.new-tag-input {
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 20px 0 0 20px;
  color: #484848;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 12px;
  outline: none;
  width: 90px;
}

.new-tag-submit {
  background-color: #ff385c;
  border: none;
  border-radius: 0 20px 20px 0;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 12px;
  transition: all 0.2s ease-in-out;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-btn-icon {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px; /* This will move the icon down 2 pixels */
  margin-left: -2px;
  margin-right: -2px;
}

.new-tag-submit:hover {
  background-color: #e31c5f;
}