.new-rec-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
  justify-content: center;
}

.new-rec-tag-bubble {
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  color: #484848;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  transition: all 0.2s ease-in-out;
  outline: none;
}

.new-rec-tag-bubble:hover {
  background-color: #ebebeb;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.new-rec-tag-bubble.new-rec-selected {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}

.new-rec-tag-bubble.new-rec-selected:hover {
  background-color: #e64a4a;
  border-color: #e64a4a;
}

.add-new-tag {
  background-color: #e0e0e0;
  color: #484848;
}

.new-tag-input-container {
  display: flex;
  align-items: center;
}

.new-tag-input {
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 30px 0 0 30px;
  color: #484848;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  outline: none;
}

.new-tag-submit {
  background-color: #ff5252;
  border: 1px solid #ff5252;
  border-radius: 0 30px 30px 0;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  transition: all 0.2s ease-in-out;
  outline: none;
}

.new-tag-submit:hover {
  background-color: #e64a4a;
  border-color: #e64a4a;
}