.tip-container {
  background-color: #ffffff;
  border-radius: 12px;
    padding: 24px;
  margin-bottom: 24px;
}

.tip-heading {
  font-size: 24px;
  font-weight: 600;
  color: #222222;
  margin-bottom: 16px;
}

.highlight {
  background: linear-gradient(120deg, #ff7e5f 0%, #feb47b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  padding: 0 5px;
  transform: skew(-5deg);
  transition: all 0.3s ease;
  margin-left: -5px;
}

.highlight:hover {
  transform: skew(-5deg) scale(1.05);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.tip-input-wrapper {
  position: relative;
}

.tip-input {
  width: 200px;
  min-height: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
  transition: border-color 0.3s ease;
  margin-left: -10px;
}

.tip-input:focus {
  outline: none;
  border-color: #008489;
  box-shadow: 0 0 0 2px rgba(0, 132, 137, 0.2);
}

.tip-input::placeholder {
  color: #717171;
}

.tip-character-count {
  position: absolute;
  bottom: -20px;
  right: -5px;
  font-size: 12px;
  color: #717171;
}

.near-limit {
  color: #ff5a5f;
  font-weight: 600;
}

.max-chars {
  margin-left: 2px;
}