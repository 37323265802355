    .rec_name {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: lowercase;
  }
  
  .summary {
    margin: 10px 0;
    text-align: center;
  }
  
  .category-tag {
    display: inline-block;
    background-color: #eee;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px;
    font-size: 0.9em;
  }
  
  .photos {
    width: 90%;
    margin-top: 10px;
  }

  .tag_and_vote_2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  
  .tag_2 {
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
  }
  
  .rec-buttons {
    display: flex !important;
    gap: 5px;
  }
  
  .rec-buttons button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 2px solid #eaeaea;
    border-radius: 5px;
    background-color: rgba(240, 240, 240, 0.5);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    color: #000; /* Added to set font color to black */
  }
  
  .rec-buttons button img {
    margin-right: 5px;
  }
  
  .rec-buttons button:hover {
    background-color: #f0f0f0;
  }

  .rec {  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }