/* FilterModal.css */
.filter-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.filter-modal-content {
  background: white;
  border-radius: 12px;
  width: 90%;
  min-height: 300px;
  max-width: 480px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.filter-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.filter-modal-header h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.filter-modal-close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #484848;
}

.filter-tabs {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #e0e0e0;
}

.filter-tab {
  flex: 1;
  padding: 15px 0;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #484848;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-tab.active {
  color: #FF5A5F;
  border-bottom: 2px solid #FF5A5F;
}

.filter-content {
  padding: 20px;
}

.filter-footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #e0e0e0;
}

.filter-clear-btn,
.filter-apply-btn {
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-clear-btn {
  background: none;
  border: 1px solid #484848;
  color: #484848;
}

.filter-apply-btn {
  background: #FF5A5F;
  border: none;
  color: white;
}

.filter-clear-btn:hover {
  background: #f7f7f7;
}

.filter-apply-btn:hover {
  background: #FF3B3F;
}

.filter-section-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #484848;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 10px;
}

.filter-modal-area-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 2px;
  margin: 5px 0;
  padding: 0;
  list-style-type: none;
}

.filter-modal-area-list .area {
  width: 100%;
  height: auto;
  min-height: 50px;
  font-size: 14px;
  padding: 4px 2px;
}

.filter-modal-area-list .area.active {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid black
}

.filter-modal-area-list .area:hover {
  border: 2px solid black
}

@media (max-width: 480px) {
  .filter-modal-area-list {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
  
  .filter-modal-area-list .area {
    font-size: 11px;
    min-height: 35px;
  }
}