/* Card.css */
.card {
  position: absolute;
  width: 300px;
  max-width: 600px;
  min-height: 450px;
  background-image: linear-gradient(135deg, white, beige, #cfb287d9);
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.114);
  display: flex;
  flex-direction: column;
  will-change: transform;
  touch-action: none;
  padding: 15px; /* Adjusted for equal padding */
  border-width: 2px;
  border-style: solid;
  text-align: center;
  scale: 1.1;
}

.card-image {
  width: 100%;
  height: 300px; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  position: relative; /* Needed for the gradient overlay */
  overflow: hidden; /* Ensure the gradient doesn't overflow */
}


.card_name {
  font-size: 28px;
  font-weight: 700;
  text-transform: lowercase;
  margin-top: 5px;
  margin-bottom: -20px;
  font-family: 'SK-Modernist', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  background: linear-gradient(120deg, var(--category-color) 0%, var(--category-color-light) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@supports (-webkit-appearance:none) {
  .card_name {
    letter-spacing: -1.5px;
  }
}

@supports (not (-webkit-hyphens:none)) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
  .card_name {
    letter-spacing: 0px;
  }
}

.card_area {
  font-size: 16px; /* Adjusted font size */
  font-weight: 700; /* Adjusted font weight */
  text-transform: uppercase; /* Changed text transform to uppercase */
  max-width: fit-content; /* Ensure the width fits the content */
  display: inline-block; /* Ensure the background only wraps around the text */
  text-align: center; /* Center the text horizontally */
  white-space: nowrap; /* Prevents text from wrapping */
  z-index: 1; /* Ensure the text is above the image */
  padding: 5px 10px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Added text shadow */
  font-family: 'Poppins', sans-serif;
  margin-left: auto;
  margin-right: auto;
  margin-top: -35px;
  margin-bottom: 5px;
}

.card_area {
  font-family: 'Poppins', sans-serif;
  line-height: 1;
  border-radius: 5px;
}

.card_summary {
  margin: 7px 0;
  text-align: center;
}

.category-tag {
  display: inline-flex; /* Change to inline-flex for better alignment */
  align-items: center;
  background-color: #eee;
  color: black;
  padding: 2px 8px;
  border-radius: 7px;
  border: none;
  font-size: 12px; /* Increased font size */
  font-weight: bold;
  text-align: center;
  text-transform: lowercase;
  margin: 2px;
  line-height: 1.5;
  font-family: 'SK-Modernist', sans-serif; /* Ensure correct font family */
  font-weight: 400; /* Ensure correct font weight */
}

.photos {
  width: 100%;
  margin-top: 10px;
}

.photo-container {
  position: relative; /* Added to position the overlay */
  overflow: hidden; /* Ensure the gradient doesn't overflow */
  max-height: 600px;
}

.card_tag_and_vote {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px !important; /* Adjust as needed */
}

.vote-buttons {
  display: flex !important;
  justify-content: space-between !important; /* Distribute space evenly */
  width: 100%; /* Ensure the vote buttons take up the full width of the container */
  margin-top: -10px;
}

.vote-buttons button {
  flex: 1 1 33.33%; /* Each button takes up a third of the container width */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px; /* Adjust padding as needed */
  margin: 0px; /* Ensure no margin between buttons */
  border: none;
  font-size: 24px;
  color: black;
}

.card_tag {
  font-size: 24px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  margin: auto;
  margin-left: -17px;
  margin-top: 2px;
  text-transform: lowercase;
  position: absolute;
  z-index: 1;
}

.card_tag span {
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); /* Needed for Safari */
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}

.card_tag_area {
  font-size: 24px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  margin: auto;
  text-transform: lowercase;
  position: absolute;
  z-index: 1;
}

.slick-dots li {
  margin-top: 5px;
  margin-bottom: 5px;
}

.slick-dots {
  bottom: 10px !important; /* Adjust this value as needed */
  z-index: 2;
}

.slick-dots li button:before {
/* Make dots white for better visibility */
  opacity: 0.75;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
}

.card-swipe-left {
  background: linear-gradient(to left, rgba(255, 0, 0, 0.5), rgba(255, 0, 0, 0));
}

.card-swipe-right {
  background: linear-gradient(to right, rgba(147, 182, 160, 0.5), rgba(0, 255, 0, 0));
}

.card-swipe-left,
.card-swipe-right {
  transition: background 0.2s ease;
}

.card-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Add a gap between the buttons */
  margin-top: 10px; /* Adjust as needed */
}

.card-buttons button {
  flex: 1; /* Each button takes up equal space */
  border: none;
  padding: 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  text-transform: lowercase;
  font-size: 16px; /* Adjust font size as needed */
}

.card-buttons button:hover {
  opacity: 0.8; /* Adjust hover opacity */
}

.no-photos p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px; /* Match the height of .card-image */
  font-size: 24px;
  color: grey;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Add these styles to your Card.css file */

.card .slick-prev,
.card .slick-next {
  z-index: 1;
}

.card .slick-prev {
  left: 10px;
}

.card .slick-next {
  right: 10px;
}

.card .slick-prev:before,
.card .slick-next:before {
  color: white; /* Make arrows white for visibility */
  opacity: 0.75;
}

.card .slick-prev:hover:before,
.card .slick-next:hover:before {
  opacity: 1;
}

.card-info-row-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; /* Added to position the overlay */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  padding: 10px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
  font-size: 14px;
}

.card-info-row-overlay > div {
  display: flex;
  align-items: center;
}

.card-info-row-overlay .icon {
  margin-right: 2px;
  
}

.card-status .status-icon {
  font-size: 0.8em;
  margin-right: 4px;
  margin-bottom: -1px;

}

.card-status .status-icon.open {
  color: #4CAF50;
}

.card-status .status-icon.closed {
  color: #F44336;
}