.deck {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  height: 80vh;
  margin: 0 auto; /* Center horizontally */
  padding: 0px; /* Add some padding */
  box-sizing: border-box; /* Include padding in width calculation */
}

.right-swiped-heading {
  margin-bottom: 10px;
}

.right-swiped-list {
  list-style-type: none;
  padding: 0;
}

.right-swiped-container {
  margin-top: 40px;
}

.no-recs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.no-recs-container p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.search-again-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #ff5252;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-again-button:hover {
  background-color: #ff3232;
}