.tag {
    display: inline-flex; /* Change to inline-flex for better alignment */
    align-items: center;
    background-color: #e92442;
    color: white;
    padding: 2px 8px;
    border-radius: 15px;
    border: none;
    font-size: 14px;  /* Increased font size */
    font-weight: bold;
    text-align: center;
    text-transform: lowercase;
    margin: 1px;
    line-height: 1.5;
}

.add-btn {
    width: 13px; /* Adjust the size of the image */
    height: 13px; /* Adjust the size of the image */
    margin-right: 3px; /* Add some spacing between the image and text */
    top: 2px;
    position: relative;
}

.tag-count {
    font-weight: italic;
    font-size: 12px; /* Increased font size */
}

.tag.selected {
    background-color: #e7d5d8; /* Change color when selected */
    color: #000000;
    box-shadow: inset 0 0 0 1px #000000; /* Add border when selected */
}

.tag.selected .tag-separator {
    color: rgb(0, 0, 0);
    position: relative;
    top: 1px;
    font-size: 14px;
    margin: 0 3px;
    font-weight: lighter;
}

h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #484848;
    text-align: center;
}


.tag-filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 200px; /* Adjust this value as needed */
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 0px; /* Add space below the container */
}

.tag-filter-title {
    font-size: 14px;
    margin-bottom: 5px; /* Space below the title */
    margin-top: -10px;
}

.tag-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.tag-text {
    display: flex;
    align-items: center;
    position: relative;
    top: -1px;
    
}

.tag-separator {
    color: rgb(230, 224, 224);
    position: relative;
    top: 1px;
    font-size: 14px;
    margin: 0 3px;
    font-weight: lighter;
}

.button-style {
    background-color: #f2f2f2; /* Different color for clear selected */
    padding: 2px 8px;
    border-radius: 15px;
    border: none;
    font-size: 14px; /* Increased font size */
    color: #e92442;
    font-weight: bold;
    text-align: center;
    text-transform: lowercase;
    cursor: pointer; /* Add cursor pointer for better UX */
    display: inline-flex;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 10px;
}

.tag-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px; /* Add space above the buttons */
}