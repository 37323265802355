/* AutoComplete.css */

.auto-complete-container {
  width: 100%;
  margin-bottom: 20px;
}

.auto-complete-input {
  width: 250px;
  padding: 14px 10px 14px 10px;
  border: 1px solid #B0B0B0;
  border-radius: 12px;
  font-size: 16px;
  color: #222222;
  background-color: #FFFFFF;
  transition: all 0.2s ease;
}

.auto-complete-input:focus {
  outline: none;
  border-color: #222222;
  box-shadow: 0 0 0 1px #222222;
}

.pac-container {
  border-radius: 0 0 12px 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: -1px;
  border: 1px solid #B0B0B0;
  border-top: none;
}

.pac-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pac-item:hover {
  background-color: #f0f0f0;
}

.pac-icon {
  display: none;
}

.pac-item-query {
  font-weight: bold;
  color: #222222;
}

.pac-item .pac-item-query + span {
  color: #717171;
}