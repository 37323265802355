/* Styles for Chrome and other browsers */
@supports (-webkit-appearance:none) {
  .rec-modal-header {
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  .rec-modal-reviews {
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-weight: 300px;
  }
  .rec-modal-hours {
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300px;
  };
  
}

.rec-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.rec-modal {
  background: white;
  border-radius: 12px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rec-modal-content {
  padding: 20px;
  position: relative;
  border: 4px solid;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.rec-modal-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rec-modal-header-main {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}

.rec-modal-header-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 4px;
  margin-top: 15px;
  position: relative;
  z-index: 10;
  width: 100%;
}

.rec-modal-header h2 {
  font-size: 1.5em;
  font-weight: 600;
  margin: 0;
  background: linear-gradient(120deg, var(--category-color) 0%, var(--category-color-light) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  padding: 0 5px;
  transition: all 0.3s ease;
  margin-top: -15px;
}

.rec-modal-address-container {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 8px 12px;
  max-width: 80%;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  position: relative;
}

.rec-modal-address-container:hover {
  background-color: #e0e0e0;
  border-color: #ff385c;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.address-icon {
  color: #ff385c;
  margin-right: 8px;
  font-size: 1em;
  transition: transform 0.3s ease;
}

.rec-modal-address {
  margin: 0;
  font-size: 0.8em;
  color: #484848;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: -5px;
  transition: color 0.3s ease;
}

.rec-modal-address-container:hover .rec-modal-address {
  color: #ff385c;
}

.rec-modal-action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  text-decoration: none;
  color: inherit;
  flex: 1;
  min-width: 0;
}

.rec-modal-action-button:hover {
  background-color: #e0e0e0;
  border-color: #ff385c;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.action-icon {
  color: #ff385c;
  margin-right: 0;
  font-size: 1em;
  transition: transform 0.3s ease;
  flex-shrink: 0;
}

.action-text {
  margin: 0;
  font-size: 0.75em;
  color: #484848;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.3s ease;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
}

.rec-modal-action-button:hover .action-text {
  color: #ff385c;
}

.rec-modal-photo-slider {
  position: relative;
  margin-bottom: 14px;
  z-index: 1;
}

.rec-modal-photo-container {
  height: 250px;
}

.rec-modal-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.rec-modal-info-row.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(5px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
}

.rec-modal-info-row.overlay > div {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.rec-modal-info-row.overlay .icon {
  margin-right: 5px;
}

.rec-modal-status .status-icon {
  font-size: 0.8em;
  margin-right: 5px;
}

.rec-modal-status .status-icon.open {
  color: #4CAF50;
}

.rec-modal-status .status-icon.closed {
  color: #F44336;
}

.rec-modal-types {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.rec-modal-type {
  background-color: #f7f7f7;
  color: #484848;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.rec-modal-expandable-sections {
  margin-top: 10px;
}

.rec-modal-section {
  border-bottom: 1px solid #ebebeb;
  overflow: hidden;
}

.section-toggle {
  width: 100%;
  background: none;
  border: none;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.section-toggle:hover {
  background-color: #f7f7f7;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.section-icon {
  font-size: 18px;
  color: #484848;
}

.section-header h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #484848;
}

.review-summary, .hours-summary {
  font-size: 14px;
  color: #767676;
}

.chevron-icon {
  font-size: 14px;
  color: #767676;
  transition: transform 0.3s ease;
}

.rec-modal-section.open .chevron-icon {
  transform: rotate(180deg);
}

.section-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  height: 80px;
}

.rec-modal-section.open .section-content {
  max-height: 300px;
  overflow-y: auto;
  padding: 8px 0px 0px 0px;
}

.section-content::-webkit-scrollbar {
  width: 8px;
}

.section-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.section-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.section-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.review-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 0px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.review-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.review-meta {
  flex-grow: 1;
}

.review-author {
  font-weight: 600;
  margin: 0;
  color: #484848;
}

.review-date {
  font-size: 12px;
  color: #767676;
  margin: 0;
}

.review-rating {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #008489;
}

.star-icon {
  font-size: 14px;
}

.review-text {
  font-size: 14px;
  padding: 0px 10px;
  line-height: 1.4;
  color: #484848;
  margin: 0;
}

.hours-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hours-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-size: 14px;
  color: #484848;
}

.hours-item:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
}

.day {
  font-weight: 600;
}

.time {
  color: #767676;
}

.rec-modal-reviews,
.rec-modal-hours {
  margin-bottom: 24px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;

}

.rec-modal-reviews h3,
.rec-modal-hours h3 {
  font-size: 18px;
  margin: 0;
  padding: 12px 16px;
  background-color: #f7f7f7;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rec-modal-reviews h3::after,
.rec-modal-hours h3::after {
  content: '\25BC';
  font-size: 12px;
  transition: transform 0.3s ease;
}

.rec-modal-reviews.open h3::after,
.rec-modal-hours.open h3::after {
  transform: rotate(180deg);
}

.rec-modal-reviews-content,
.rec-modal-hours-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.rec-modal-reviews.open .rec-modal-reviews-content,
.rec-modal-hours.open .rec-modal-hours-content {
  max-height: 500px;
  padding: 16px;
}

.rec-modal-reviews .slick-slider {
  padding: 0 25px;
}

.rec-modal-reviews .slick-prev,
.rec-modal-reviews .slick-next {
  width: 25px;
  height: 100%;
  z-index: 1;
}

.rec-modal-reviews .slick-prev {
  left: 0;
}

.rec-modal-reviews .slick-next {
  right: 0;
}

.review {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.review-content {
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.review-text {
  font-style: italic;
  margin-bottom: 10px;
}

.review-author {
  font-size: 0.9em;
}

.review-author strong {
  display: block;
  margin-bottom: 5px;
}

/* Ensure consistent height for all slides */
.rec-modal-reviews .slick-track {
  display: flex !important;
}

.rec-modal-reviews .slick-slide {
  height: inherit !important;
  display: flex !important;
}

.rec-modal-reviews .slick-slide > div {
  display: flex;
  width: 100%;
}

.rec-modal-reviews .slick-dots {
  bottom: -5px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
}

.rec-modal-reviews .slick-dots li {
  margin: 0 5px;
}

.rec-modal-reviews .slick-dots li button:before {
  font-size: 10px;
  color: #000;
}

.rec-modal-reviews .slick-dots li.slick-active button:before {
  color: #000;
  opacity: 1;
}

.rec-modal-hours h3 {
  display: flex;
  align-items: center;
}

.rec-modal-hours ul {
  list-style-type: none;
  padding: 0;
}

.rec-modal-hours li {
  margin-bottom: 4px;
}

.rec-modal-contact {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}

.rec-modal-phone,
.rec-modal-website {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ff5252;
  font-weight: 600;
}

.icon {
  margin-right: 8px;
}

.rec-modal-favorite {
  width: 100%;
  background-color: #ff5a5f;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rec-modal-favorite:hover {
  background-color: #ff385c;
}

.rec-modal-favorite .icon {
  margin-right: 8px;
}

.rec-modal .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s ease;
}

.rec-modal .slick-arrow:hover {
  background: rgba(255, 255, 255, 1);
}

.rec-modal .slick-prev {
  left: 16px;
}

.rec-modal .slick-next {
  right: 16px;
}

.rec-modal .slick-arrow svg {
  width: 24px;
  height: 24px;
}

.rec-modal .slick-arrow svg path {
  stroke: #000000;
}

.rec-modal .slick-prev:before,
.rec-modal .slick-next:before {
  display: none;
}

.rec-modal .slick-arrow svg {
  width: 24px;
  height: 24px;
}

.rec-modal .slick-arrow svg path {
  stroke: #000000;
}

.rec-modal-reviews .slick-prev,
.rec-modal-reviews .slick-next {
  width: 25px;
  height: 100%;
  z-index: 1;
}

.rec-modal-reviews .slick-prev {
  left: 0;
}

.rec-modal-reviews .slick-next {
  right: 0;
}

.rec-modal-photo-slider .slick-dots {
  position: absolute;
  bottom: 50px !important;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex !important;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.rec-modal-photo-slider .slick-dots li {
  margin: 0 4px;
}

.rec-modal-photo-slider .slick-dots li button {
  width: 8px;
  height: 8px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  text-indent: -9999px;
  cursor: pointer;
}

.rec-modal-photo-slider .slick-dots li.slick-active button {
  background: #ffffff;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #666;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 5px;
  transition: color 0.3s ease;
  z-index: 10;
}

.close-button:hover {
  color: #333;
}

.rec-modal-header-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.rec-modal-categories-wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0px 0px;
}

.rec-modal-categories {
  display: flex;
  gap: 8px;
  padding-bottom: 4px;
  justify-content: center;
  text-transform: lowercase;
}

.rec-modal-category-tag {
  background-color: var(--category-color);
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 12px;
  border-radius: 10px;
  letter-spacing: 1px;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.rec-modal-category-tag:hover {
  background-color: var(--category-color);
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.rec-modal-categories-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.rec-modal-categories-wrapper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.section-toggle {
  width: 100%;
  background: none;
  border: none;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.section-icon {
  color: var(--category-color);
  font-size: 24px;
}

.section-header h3 {
  font-size: 18px;
  font-weight: 600;
  color: #484848;
  margin: 0;
}

.tips-summary {
  font-size: 14px;
  color: #767676;
}

.chevron-icon {
  color: #484848;
  transition: transform 0.3s ease;
}

.chevron-icon.open {
  transform: rotate(180deg);
}

.tips-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
  display: flex;
  flex-direction: column;
}

.tips-section.open .tips-content {
  max-height: 1000px;
}

.tip-cards-container {
  margin-bottom: 16px;
}

.tip-card {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 12px 16px;
  margin-bottom: 12px;
  transition: all 0.3s ease;
}

.tip-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tip-text {
  font-size: 14px;
  line-height: 1.4;
  color: #484848;
  margin: 0 0 8px 0;
}

.tip-meta {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #767676;
}

.tip-author, .tip-date {
  display: flex;
  align-items: center;
  gap: 4px;
}

.user-icon, .calendar-icon {
  font-size: 10px;
}

.no-tips {
  font-style: italic;
  color: #767676;
  text-align: center;
  padding: 16px 0;
}

.tips-and-button-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.tips-container {
  width: 100%;
  padding-right: 0;
  margin-bottom: 8px;
}

.add-tip-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ff5252; 
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: -8px;
}

.add-tip-button:hover {
  background-color: #ff3838;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-tip-icon {
  font-size: 18px;
  margin-right: 8px;
}

/* New media query for mobile devices */
@media screen and (max-width: 480px) {
  .rec-modal-header-actions {
    justify-content: space-around;
  }

  .rec-modal-action-button {
    flex: 0 1 auto;
    gap: 4px;
    padding: 8px 20px;
  }

  .action-text {
    display: none;
  }

  .action-icon {
    margin-right: 0;
    font-size: 1.2em;
  }
}

.tip-input-wrapper {
  position: relative;
  margin-top: 16px;
}

.tip-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
}

.tip-input:focus {
  outline: none;
  border-color: var(--category-color);
  box-shadow: 0 0 0 2px rgba(var(--category-color-rgb), 0.2);
}

.submit-tip-button {
  width: 100%;
  margin-top: 8px;
  padding: 12px;
  background-color: var(--category-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-tip-button:hover {
  background-color: var(--category-color-mid);
}

.tips-summary-container {
  display: flex;
  align-items: center;
}

.add-tip-button-small {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--category-color-light);
  border-radius: 12px;
  transition: background-color 0.2s ease;
}

.add-tip-button-small:hover {
  background-color: var(--category-color-mid);
}

.add-tip-icon-small {
  font-size: 12px;
  color: white;
  margin-right: 4px;
}

.add-tip-text {
  font-size: 12px;
  color: white;
  font-weight: 500;
}

.rec-modal-tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 5px;
}

.rec-modal-tab {
  flex: 1;
  padding: 8px 12px;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #484848;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.rec-modal-tab.active {
  color: var(--category-color);
  border-bottom: 2px solid var(--category-color);
}

.rec-modal-tab svg {
  font-size: 18px;
  margin-bottom: 0;
  order: 2;
}

.rec-modal-tab-content {
  padding: 0px 0 0 0;
  max-height: 90px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.rec-modal-tab-content::-webkit-scrollbar {
  width: 6px;
}

.rec-modal-tab-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.rec-modal-tab-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.rec-modal-tab-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.filter-section-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #484848;
}

/* Adjust for mobile if needed */
@media screen and (max-width: 480px) {
  .rec-modal-tab {
    font-size: 14px;
    padding: 8px 6px;
    gap: 4px;
  }
  
  .rec-modal-tab svg {
    font-size: 16px;
  }
}