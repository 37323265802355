/* CategoryFilter.css */
.category-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .category-list li {
    margin: 8px 0;
  }
  
  .category-button {
    background-color: white;
    color: #E22C3C;
    border-radius: 20px;
    border: none;
    padding: 12px;
    font-size: 24px;
    font-weight: 800;
    text-transform: lowercase;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    width: 350px; /* Adjust the width as needed */
    text-align: center;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15); /* Increased shadow */
  }
  
  .category-button.active,
  .category-button:hover {
    background-color: #ff5252;
    color: white;
  }
  
  .category-button:focus {
    outline: none;
  }
  