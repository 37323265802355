/* Modal styles */
.new-rec-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.new-rec-modal-content {
  background: white;
  padding: 20px;
  border-radius: 16px;
  width: 350px; /* Set a fixed width for mobile */
  max-width: 90%; /* Reduce the maximum width */
  max-height: 80vh; /* Set maximum height to 80% of viewport height */
  min-height: 100px;
  overflow-y: auto; /* Add vertical scrolling if content exceeds max-height */
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  font-family: 'Airbnb Cereal', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media (min-width: 768px) {
  .new-rec-modal-content {
    width: 450px; /* Slightly wider for tablets and desktops */
  }
}

.new-rec-modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px; /* Increase space between header and form */
  margin-top: 10px;
  position: relative;
}

.new-rec-title {
  flex-grow: 0;
  text-align: center;
  margin: 0;
}

.new-rec-back-btn {
  background: none;
  border: none;
  font-size: 16px;
  color: black;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 5px;
  position: absolute;
  left: 0;
}

.new-rec-back-btn:hover {
  color: #ff5252;
}

.new-rec-back-btn svg {
  margin-right: 5px;
}

.new-rec-back-btn span {
  font-weight: 500;
}

.new-rec-close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 0;
  margin-top: 5px;
}

.new-rec-close-btn:hover {
  color: #FF5A5F;
  transition: all 0.2s ease;
}

.form-group h2 {
  margin-top: -20px;
}

.two-column-area-filter {
  margin-bottom: 15px !important;
}

.form-group .filter-buttons {
  scale: .8;
}

.form-group h

.input-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #717171;
  font-size: 18px;
  z-index: 1;
}



@media (max-width: 768px) {
  
  .input-icon {
    left: 20px;
  }
}

.new-rec-form input:focus,
.new-rec-form select:focus {
  outline: none;
  border-color: #222222;
  box-shadow: 0 0 0 1px #222222;
}

.new-rec-form input::placeholder {
  color: #717171;
}

.new-rec-form select {
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23222222' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
}

.submit-button {
  background-color: #FF385C;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  margin-top: 20px; /* Increase space above the submit button */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.submit-button:hover:not(:disabled) {
  background-color: #D70466;
}

.submit-button:disabled {
  background-color: #DDDDDD;
  color: #717171;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .submit-button {
    font-size: 14px;
    padding: 14px 20px 14px 20px;
  }
  
  .input-icon {
    font-size: 16px;
    left: 14px;
  }
}


.area-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}

.category-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}

.share-recommendation-button {
  background-color: #f2f2f2;
  color: #484848;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 300;
  cursor: pointer;
  transition: background-color 0.3s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.share-recommendation-button:hover {
  background-color: #e6e6e6;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .share-recommendation-button {
    font-weight: 300;
  }
}