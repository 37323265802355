/* FilterPage.css */
.filter-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Poppins', sans-serif;

  margin-top: 30px;
}

h2 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 2em;
}

.filter-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px; /* Adjust spacing between rows of buttons */
}

.filter-button {
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  border: 1px solid white;
  border-radius: 20px;
  background-color: #f2f2f2;
  font-size: 16px;
  cursor: pointer;
}

.filter-button.active {
  background-color: #ff5252;
  color: white;
  font-weight: 600;
  border: 3px solid white;
}

.filter-page h3 {
  color: black;
}
 
.see-more-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #ff5252;
  cursor: pointer;
  margin-bottom: 20px;
  display: block; /* Ensures button behaves like a block element */
  margin-left: auto;
  margin-right: auto; /* Centers the button horizontally */
}

.filter-location {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.filter-location label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 20px;
}

.filter-location input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.filter-continue-button {
  background-color: #ff5252;
  color: white;
  padding: 10px 20px;
  border: 2px solid #fefefe;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 5px;
}

.filter-continue-button {
  box-shadow: 0 0 10px rgba(255, 64, 129, 0.5);
  transition: all 0.3s ease;
}

.filter-continue-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(255, 64, 129, 0.7);
}
