.search-container {
  position: relative;
  max-width: 400px; /* Adjust this value to match the width of your recommendations */
  margin: auto;
}

.search-box {
  width: 90%;
  padding: 10px 15px;
  border: 2px solid #ddd;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
}

.search-box:focus {
  border-color: #ff5252;
  box-shadow: 0 4px 6px rgba(255, 82, 82, 0.2);
}

.search-results {
  position: absolute;
  top: calc(100%); /* Position results just below the search box */
  left: 0;
  width: 98%; /* Ensure the results box is the same width as the search box */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  max-height: 300px; /* Limit the height of the results box */
  overflow-y: auto; /* Add scroll for overflow */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.search-result-item {
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.search-result-item:last-child {
  border-bottom: none;
}

.search-result-item:hover {
  background-color: #f2f2f2;
}

.share-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.share-container p {
  margin: 0;
}

.share-button {
  background-color: #ff5252;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.share-button:hover {
  background-color: #ff5252;
}

.instructions-container {
  text-align: center;
  margin-top: 10px;
}

.instructions-container p {
  margin: 5px 0;
}

.rec.rank-1 {
  border: 3px solid #dfb83b !important;
  box-shadow: 0 0 5px #bb9b31;
  transform: scale(1.00);
  transition: transform 0.3s ease;
  z-index: 1;
}

.rec.rank-1:hover {
  transform: scale(1.00);
}

.rec.rank-2 {
  border: 3px solid rgb(164, 164, 164) !important;
  box-shadow: 0 0 10px silver;
  transform: scale(1.00);
  transition: transform 0.3s ease;
  z-index: 1;
}

.rec.rank-2:hover {
  transform: scale(1.00);
}

.rec.rank-3 {
  border: 3px solid #cd7f32 !important; /* bronze color */
  box-shadow: 0 0 10px #cd7f32;
  transform: scale(1.00);
  transition: transform 0.3s ease;
  z-index: 1;
}

.rec.rank-3:hover {
  transform: scale(1.00);
}

.group-rec {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
}


.group-rec-content {
  padding: 16px;
}

.group-rec-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.group-rec-rank {
  font-size: 14px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 12px;
  color: #ffffff;
  margin-right: 12px;
}

.group-rec-name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  flex-grow: 1;
}

.group-rec-area {
  font-size: 14px;
  color: #717171;
}


.group-rec-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.group-rec-category {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 12px;
}

.group-rec-swipes {
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
}

.swipe-bar {
  height: 8px;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f2f2f2;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border: 0px solid white;
}

.swipe-bar-left,
.swipe-bar-right {
  height: 100%;
  transition: width 0.3s ease;
}

.swipe-bar-left {
  background-color: #00A699; /* Airbnb teal */
}

.swipe-bar-right {
  background-color: #FF5A5F; /* Airbnb coral */
}

.swipe-counts {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 100%;
  font-size: 14px;
  color: #484848; /* Airbnb dark gray */
  margin-top: 3px;
}

.swipe-count-left,
.swipe-count-right {
  font-weight: 600;
}

.group-rec-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-rec-category-tag {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 12px;
}