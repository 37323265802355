.liquid-gradient-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(45deg, #ff385c, #ff9a5a, #ffd579);
  background-size: 400% 400%;
  animation: gradientFlow 10s ease infinite;
  opacity: 0.15; /* Default opacity for Home.js */
}

.liquid-gradient-background.category-page-gradient {
  opacity: 0.5; /* Adjusted opacity for CategoryPage */
  animation: gradientFlow 15s ease infinite;
}

.liquid-gradient-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, transparent 0%, rgba(255, 255, 255, 0.5) 100%);
  filter: blur(40px);
  animation: pulseGlow 8s ease-in-out infinite alternate;
}

@keyframes gradientFlow {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

@keyframes pulseGlow {
  0% { opacity: 0.5; transform: scale(1); }
  100% { opacity: 0.8; transform: scale(1.1); }
}

.category-page {
  position: relative;
  z-index: 1;
}

.category-content {
  position: relative;
  z-index: 2;
}